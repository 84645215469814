var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { MultiSelectionHeader } from "./multiselection-styles";
import { useTranslation } from "react-i18next";
export var MultiSelectionWrapper = function (props) {
    var _a = __read(React.useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var t = useTranslation(['advanced-search']).t;
    var toogleMenu = function () {
        if (isOpen) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    };
    React.useEffect(function () {
        if (props.isOpen && !isOpen) {
            setIsOpen(true);
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(MultiSelectionHeader, __assign({ onClick: toogleMenu, marginTop: props.marginTop, marginBottom: props.marginBottom }, { children: [t(props.title), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-").concat(isOpen ? "up" : "down", ".svg"), alt: isOpen ? "arrow up" : "arrow down" })] })), isOpen && props.children] }));
};
