var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { CleanFilterBtn } from "../doc-lib-components/Views/Filters-styles";
import { useTranslation } from "react-i18next";
import MapContext from "../../../application/Context/MapContext";
export var CleanFilters = function () {
    var t = useTranslation(["news"]).t;
    var resetFilters = React.useContext(MapContext).resetFilters;
    return (_jsx(CleanFilterBtn, __assign({ onClick: resetFilters }, { children: t("Réinitialiser la recherche") })));
};
