import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import ReactPaginate from "react-paginate";
import MapContext from "../../../application/Context/MapContext";
export var Pagination = function () {
    var _a = useContext(MapContext), handlePageClick = _a.handlePageClick, pageCount = _a.pageCount, page = _a.page;
    React.useEffect(function () { }, []);
    return (_jsx(React.Fragment, { children: pageCount > 1 &&
            _jsx(ReactPaginate, { previousLabel: "<", nextLabel: ">", breakLabel: "...", breakClassName: "break-me", pageCount: pageCount, marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: handlePageClick, containerClassName: "pagination", subContainerClassName: "pages pagination", activeClassName: "active", forcePage: page }) }));
};
