var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import MultiSelect from "../shared-components/MultiSelect/MultiSelect";
import MapContext from "../../../application/Context/MapContext";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import RemoteMultiSelect from "../shared-components/MultiSelect/RemoteMultiSelect";
export var Filters = function () {
    var _a;
    var t = useTranslation(["news"]).t;
    var currentAuthStatus = ((_a = document.getElementById("auth-user")) === null || _a === void 0 ? void 0 : _a.value) || false;
    var isAuthenticated = currentAuthStatus == "True" ? true : false;
    var _b = React.useContext(MapContext), categories = _b.categories, selectedCategories = _b.selectedCategories, selectedSectors = _b.selectedSectors, handleCategoriesChange = _b.handleCategoriesChange, handleSectorsChange = _b.handleSectorsChange, handleRegionsChange = _b.handleRegionsChange, selectedRegions = _b.selectedRegions;
    var customLabelFunction = function (option, langCode) {
        return "".concat(option["code"], " - ").concat(option["title_".concat(langCode)]);
    };
    return (_jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.2 }, exit: { opacity: 0 } }, { children: isAuthenticated && (_jsxs(_Fragment, { children: [_jsx(RemoteMultiSelect, { title: t("Régions"), placeHolder: t("Sélectionner une ou des régions"), handleClick: handleRegionsChange, SelectedChoices: selectedRegions, relatedField: "adresses.region_adminsitrative", ESindex: "Company", column: "nom", operator: "icontains", valueColumn: "id", getCustomLabel: function (option) { return option.nom; }, others: [{ column: "in_north_region", value: false }] }), _jsx(MultiSelect, { title: t("Types"), placeHolder: t("Sélectionner un ou des types"), data: categories.map(function (e) {
                        return { id: e[0], value: e[1] };
                    }), handleClick: handleCategoriesChange, SelectedChoices: selectedCategories }), _jsx(RemoteMultiSelect, { title: t("Secteurs d'activité"), placeHolder: t("Sélectionner un ou des secteurs d'activité"), handleClick: handleSectorsChange, SelectedChoices: selectedSectors, relatedField: "code_scian_primaire,code_scian_secondaire", ESindex: "Company", column: ["code", "title", "is_visible"], operator: ["istartswith", "icontains", "is"], valueColumn: "code", getCustomLabel: customLabelFunction, customFilterType: "scian" })] })) })));
};
