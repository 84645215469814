var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CardStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\npadding: 10px 5px;\nposition:absolute;\nz-index: ", ";\nwidth:300px;\nbackground-color: #f1f1f2;\ncolor: #223554;\npadding: 12px 20px;\nbox-shadow: 0 1px 4px #22365429;\n    @media screen and (min-width: 768px) {\n        left:10%;\n        top: ", "px;\n    }\n    @media screen and (max-width: 768px) {\n        right:10%;\n        top: 40px;\n    }\n"], ["\npadding: 10px 5px;\nposition:absolute;\nz-index: ", ";\nwidth:300px;\nbackground-color: #f1f1f2;\ncolor: #223554;\npadding: 12px 20px;\nbox-shadow: 0 1px 4px #22365429;\n    @media screen and (min-width: 768px) {\n        left:10%;\n        top: ", "px;\n    }\n    @media screen and (max-width: 768px) {\n        right:10%;\n        top: 40px;\n    }\n"])), function (_a) {
    var zIndex = _a.zIndex;
    return zIndex;
}, function (_a) {
    var cardPosition = _a.cardPosition;
    return cardPosition;
});
var templateObject_1;
