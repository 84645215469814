var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Suppliers = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nbackground: #4a98da;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #4a98da;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"], ["\nbackground: #4a98da;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #4a98da;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"])));
export var Intervenants = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nbackground: #ad791d;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #ad791d;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"], ["\nbackground: #ad791d;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #ad791d;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"])));
export var OrderGiver = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nbackground: #19406c;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #19406c;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"], ["\nbackground: #19406c;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #19406c;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"])));
export var PublicNotices = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nbackground: #4f813d;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #4f813d;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"], ["\nbackground: #4f813d;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #4f813d;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"])));
export var Market = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nbackground: #6b778a;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #6b778a;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"], ["\nbackground: #6b778a;\nborder: 1px solid #fff;\ncursor:pointer;\ncolor: #fff;\nheight: 20px;\nwidth: 20px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ntransform: translate(-25%,-50%) rotate(-90deg);\n&:before{\n    content: \"\";\n    position: absolute;\n    right: 100%;\n    top: 14px;\n    width: 0;\n    height: 0;\n    border-top: 6px solid transparent;\n    border-right: 6px solid #6b778a;\n    border-bottom: 6px solid transparent;\n    transform: translate(0, -11px);\n}\n\ndiv{\n    transform: rotate(90deg);\n}\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
