var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
import { useTranslation } from "react-i18next";
var MapContext = createContext();
export var MapProvider = function (_a) {
    var _b;
    var children = _a.children;
    var t = useTranslation(["common"]).t;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    // Map States
    var _c = __read(React.useState([]), 2), companies = _c[0], setCompanies = _c[1];
    var _d = __read(React.useState([]), 2), projects = _d[0], setProjects = _d[1];
    var _e = __read(React.useState([]), 2), companiesList = _e[0], setCompaniesList = _e[1];
    var _f = __read(React.useState([]), 2), projectsList = _f[0], setProjectsList = _f[1];
    var _g = __read(React.useState("carte"), 2), view = _g[0], setView = _g[1];
    var _h = __read(React.useState(true), 2), datasIsLoading = _h[0], setDatasIsLoading = _h[1];
    var _j = __read(React.useState(true), 2), datasListIsLoading = _j[0], setDatasListIsLoading = _j[1];
    var _k = __read(React.useState(Math.random().toString()), 2), updatedKey = _k[0], setUpdatedKey = _k[1];
    var _l = __read(useState(true), 2), initialFetch = _l[0], setInitialFetch = _l[1];
    var _m = __read(useState(true), 2), initialDatas = _m[0], setInitialDatas = _m[1];
    var _o = __read(useState(false), 2), secondDatas = _o[0], setSecondDatas = _o[1];
    // Search States
    var _p = __read(React.useState(""), 2), searchInput = _p[0], setSearchInput = _p[1];
    var _q = __read(React.useState(""), 2), searchedTerm = _q[0], setSearchedTerm = _q[1];
    // Filter States
    var _r = __read(React.useState([]), 2), regions = _r[0], setRegions = _r[1];
    var _s = __read(React.useState([]), 2), regionsNorth = _s[0], setRegionsNorth = _s[1];
    var _t = __read(React.useState([]), 2), selectedRegions = _t[0], setSelectedRegions = _t[1];
    // const [selectedRegionsNorth, setSelectedRegionsNorth] = React.useState([]);
    var _u = __read(React.useState([]), 2), categories = _u[0], setCategories = _u[1];
    var _v = __read(React.useState([]), 2), selectedCategories = _v[0], setSelectedCategories = _v[1];
    var _w = __read(React.useState([]), 2), sectors = _w[0], setSectors = _w[1];
    var _x = __read(React.useState([]), 2), selectedSectors = _x[0], setSelectedSectors = _x[1];
    // Pagination State
    var _y = __read(React.useState(0), 2), page = _y[0], setPage = _y[1];
    var _z = __read(React.useState(1), 2), pageCount = _z[0], setPageCount = _z[1];
    // query
    var _0 = __read(useState([
        {
            order: 100000001,
            condition: "where",
            field: "is_member",
            operator: "is",
            value: true,
        },
        {
            order: 100000001,
            condition: "and",
            field: "adresses.id",
            operator: "greater",
            value: 0,
        },
        {
            order: 100000001,
            condition: "and",
            field: "adresses.latitude_coordonnate",
            operator: "greater",
            value: -1000000000,
        },
        {
            order: 100000001,
            condition: "and",
            field: "adresses.longitude_coordonnate",
            operator: "greater",
            value: -1000000000,
        },
        {
            order: 100000001,
            condition: "and",
            field: "status",
            operator: "is",
            value: ["actived", "imported"],
        },
        /*{
          order: 100000001,
          condition: "and",
          field: "code_scian_primaire.is_visible",
          operator: "is",
          value: true,
        },*/
    ]), 2), rowsCompanies = _0[0], setRowsCompanies = _0[1];
    var _1 = __read(useState([
        {
            order: 100000001,
            condition: "where",
            field: "adresse.id",
            operator: "greater",
            value: 0,
        },
        {
            order: 100000001,
            condition: "and",
            field: "adresse.latitude_coordonnate",
            operator: "greater",
            value: -1000000000,
        },
        {
            order: 100000001,
            condition: "and",
            field: "adresse.longitude_coordonnate",
            operator: "greater",
            value: -1000000000,
        },
        {
            order: 100000001,
            condition: "and",
            field: "visible_publiquement",
            operator: "is",
            value: true,
        },
        {
            order: 100000001,
            condition: "and",
            field: "entreprise.id",
            operator: "greater",
            value: 0,
        },
        {
            order: 100000001,
            condition: "and",
            field: "entreprise.status",
            operator: "is",
            value: ["actived", "imported"],
        },
        /*{
          order: 100000001,
          condition: "and",
          field: "entreprise.code_scian_primaire.is_visible",
          operator: "is",
          value: true,
        },*/
        {
            order: 100000001,
            condition: "and",
            field: "visible_in_map",
            operator: "is",
            value: true,
        },
    ]), 2), rowsProjects = _1[0], setRowsProjects = _1[1];
    // Get fields
    useEffect(function () {
        fetchField();
    }, []);
    // Initial
    useEffect(function () {
        if (initialFetch) {
            // We need that else to show all companies if no default company type is set
            var searchData = {
                query: searchedTerm,
                size: 2000,
                page: page + 1,
                filters: [
                    {
                        Company: { conditions: rowsCompanies },
                    },
                    {
                        Project: { conditions: rowsProjects },
                    },
                ],
            };
            fetchDatas(searchData);
            // fetchCompanies(searchDataCompanies);
            // fetchProjects(searchDataProjects);
            setInitialFetch(false);
        }
    }, []);
    // Fetch fields
    var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, fCategories, fSectors, modifiedCategories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Company&context=filters&no_fetching"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    fCategories = data.filter(function (obj) { return obj.field_name == "type_company"; });
                    fSectors = data.filter(function (obj) { return obj.field_name == "code_scian_primaire"; });
                    modifiedCategories = fCategories[0].choices_labeled.map(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], value = _b[1];
                        var translatedValue;
                        if (langCode === "fr") {
                            translatedValue = {
                                "Fournisseur": "Fournisseurs",
                                "Donneur d'ordres": "Donneurs d'ordres",
                                "Intervenant": "Intervenants",
                            }[value] || value;
                        }
                        else if (langCode === "en") {
                            translatedValue = {
                                "Supplier": "Suppliers",
                                "Contractor": "Contractors",
                                "Intervener": "Stakeholders",
                            }[value] || value;
                        }
                        return [key, translatedValue];
                    });
                    setCategories(modifiedCategories);
                    setCategories(function (prev) {
                        return __spreadArray(__spreadArray([], __read(prev), false), [
                            ["avis", t("Avis publics")],
                            ["nonavis", t("Avis des donneurs d'ordres")],
                        ], false);
                    });
                    setSectors(fSectors[0].values);
                    return [2 /*return*/];
            }
        });
    }); };
    // Highest order
    function getHighestOrder(array) {
        var highestPrice = 99999999;
        for (var i = 0; i < array.length; i++) {
            if (array[i].order > highestPrice) {
                highestPrice = array[i].order;
            }
        }
        return highestPrice;
    }
    // Get Datas
    useEffect(function () {
        // ===--- Companies
        sortArray(rowsCompanies, {
            by: ["order"],
        });
        if (rowsCompanies.length > 0 && rowsCompanies[0].condition !== "where") {
            rowsCompanies[0].condition = "where";
        }
        var lastorder = 100000001;
        for (var i = 1; i < rowsCompanies.length; i++) {
            if (rowsCompanies[i].field != "adresses.id" &&
                rowsCompanies[i].field != "adresses.latitude_coordonnate" &&
                rowsCompanies[i].field != "adresses.longitude_coordonnate" &&
                rowsCompanies[i].field != "status" &&
                rowsCompanies[i].field != "is_member" // &&
            //rowsCompanies[i].field != "code_scian_primaire.is_visible"
            ) {
                if (rowsCompanies[i].order != lastorder) {
                    rowsCompanies[i].condition = "and";
                }
                else {
                    rowsCompanies[i].condition = "or";
                }
                lastorder = rowsCompanies[i].order;
            }
            if (rowsCompanies[i].field == "adresses.region_adminsitrative.id"
                || rowsCompanies[i].field == "autres_address.region_adminsitrative.id"
                || rowsCompanies[i].field == "code_scian_primaire.code"
                || rowsCompanies[i].field == "code_scian_secondaire.code") {
                rowsCompanies[i].condition = "or";
            }
            if (rowsCompanies[i].field == "adresses.region_adminsitrative.id"
                || rowsCompanies[i].field == "autres_address.region_adminsitrative.id") {
                rowsCompanies[i].group = "region";
            }
            if (rowsCompanies[i].field == "code_scian_primaire.code"
                || rowsCompanies[i].field == "code_scian_secondaire.code") {
                rowsCompanies[i].group = "scian";
            }
        }
        // ===--- Projects
        sortArray(rowsProjects, {
            by: ["order"],
        });
        if (rowsProjects.length > 0 && rowsProjects[0].condition !== "where") {
            rowsProjects[0].condition = "where";
        }
        lastorder = 100000001;
        for (var i = 1; i < rowsProjects.length; i++) {
            if (rowsProjects[i].field != "adresse.id" &&
                rowsProjects[i].field != "adresse.latitude_coordonnate" &&
                rowsProjects[i].field != "adresse.longitude_coordonnate" &&
                rowsProjects[i].field != "visible_publiquement" &&
                rowsProjects[i].field != "entreprise.id" &&
                rowsProjects[i].field != "entreprise.status" &&
                //rowsProjects[i].field != "entreprise.code_scian_primaire.is_visible" &&
                rowsProjects[i].field != "visible_in_map") {
                if (rowsProjects[i].order != lastorder) {
                    rowsProjects[i].condition = "and";
                }
                else {
                    rowsProjects[i].condition = "or";
                }
                lastorder = rowsProjects[i].order;
            }
        }
        // ===---
        var filtersList = [
            {
                Company: { conditions: rowsCompanies },
            },
            {
                Project: { conditions: rowsProjects },
            },
        ];
        if (selectedCategories.length > 0 &&
            selectedCategories.some(function (obj) { return (obj.value === "avis" || obj.value === "nonavis"); }) &&
            !selectedCategories.some(function (obj) { return (obj.value === "contractor" || obj.value === "supplier" || obj.value === "intervenant"); })) {
            filtersList = [
                {
                    Project: { conditions: rowsProjects },
                },
            ];
        }
        else if (selectedCategories.length > 0 &&
            !selectedCategories.some(function (obj) { return (obj.value === "avis" || obj.value === "nonavis"); }) &&
            selectedCategories.some(function (obj) { return (obj.value === "contractor" || obj.value === "supplier" || obj.value === "intervenant"); })) {
            filtersList = [
                {
                    Company: { conditions: rowsCompanies },
                },
            ];
        }
        // ===---
        var searchData = {
            query: searchedTerm,
            size: 2000,
            page: page + 1,
            filters: filtersList,
        };
        if (!datasIsLoading) {
            fetchDatas(searchData);
        }
    }, [rowsCompanies, rowsProjects, searchedTerm, page]);
    // Fetch Companies
    var fetchDatas = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        function postData(url, data) {
            if (url === void 0) { url = "/".concat(langCode, "/api/search/"); }
            if (data === void 0) { data = {}; }
            return __awaiter(this, void 0, void 0, function () {
                var response, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(url, {
                                method: "POST",
                                mode: "cors",
                                cache: "no-cache",
                                credentials: "same-origin",
                                headers: {
                                    "X-CSRFToken": CSRF_TOKEN,
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                                body: JSON.stringify(data),
                            })];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            res = _a.sent();
                            return [2 /*return*/, res];
                    }
                });
            });
        }
        var CSRF_TOKEN;
        return __generator(this, function (_a) {
            setDatasIsLoading(true);
            setDatasListIsLoading(true);
            CSRF_TOKEN = Cookies.get("csrftoken");
            if (page === 0) {
                postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                    var nbrPages = data.count / 10;
                    setPageCount(Math.ceil(nbrPages));
                    var resultCompanies = data.results
                        ? data.results
                            .filter(function (data) {
                            if (data._index == "companies-index") {
                                return data.result;
                            }
                        })
                            .map(function (data) {
                            return data.result;
                        })
                        : [];
                    var resultProjects = data.results
                        ? data.results
                            .filter(function (data) {
                            if (data._index == "projects-index") {
                                return data.result;
                            }
                        })
                            .map(function (data) {
                            return data.result;
                        })
                        : [];
                    // ===---
                    if (selectedCategories.length <= 0 ||
                        selectedCategories.some(function (obj) { return obj.value != "avis" && obj.value != "nonavis"; })) {
                        setCompanies(function () {
                            return resultCompanies;
                        });
                    }
                    else {
                        setCompanies([]);
                    }
                    if (selectedCategories.length <= 0 ||
                        selectedCategories.some(function (obj) { return obj.value == "avis" || obj.value == "nonavis"; })) {
                        setProjects(function () {
                            return resultProjects;
                        });
                    }
                    else {
                        setProjects([]);
                    }
                    // ===---
                    setUpdatedKey(Math.random().toString());
                    setDatasIsLoading(false);
                    setDatasListIsLoading(false);
                    if (secondDatas) {
                        setInitialDatas(false);
                    }
                    setSecondDatas(true);
                });
            }
            // for listview
            data["size"] = 10;
            data["page"] = page + 1,
                postData("/".concat(langCode, "/api/search/"), data).then(function (data) {
                    var nbrPages = data.count / 10;
                    setPageCount(Math.ceil(nbrPages));
                    var resultCompanies = data.results
                        ? data.results
                            .filter(function (data) {
                            if (data._index == "companies-index") {
                                return data.result;
                            }
                        })
                            .map(function (data) {
                            return data.result;
                        })
                        : [];
                    var resultProjects = data.results
                        ? data.results
                            .filter(function (data) {
                            if (data._index == "projects-index") {
                                return data.result;
                            }
                        })
                            .map(function (data) {
                            return data.result;
                        })
                        : [];
                    // ===---
                    if (selectedCategories.length <= 0 ||
                        selectedCategories.some(function (obj) { return obj.value != "avis" && obj.value != "nonavis"; })) {
                        setCompaniesList(function () {
                            return resultCompanies;
                        });
                    }
                    else {
                        setCompaniesList([]);
                    }
                    if (selectedCategories.length <= 0 ||
                        selectedCategories.some(function (obj) { return obj.value == "avis" || obj.value == "nonavis"; })) {
                        setProjectsList(function () {
                            return resultProjects;
                        });
                    }
                    else {
                        setProjectsList([]);
                    }
                    // ===---
                    setUpdatedKey(Math.random().toString());
                    setDatasIsLoading(false);
                    setDatasListIsLoading(false);
                    if (secondDatas) {
                        setInitialDatas(false);
                    }
                    setSecondDatas(true);
                });
            return [2 /*return*/];
        });
    }); };
    // Reset filter
    var resetFilters = function () {
        setSelectedRegions([]);
        setSelectedCategories([]);
        setSelectedSectors([]);
        setRowsCompanies([
            {
                order: 100000001,
                condition: "where",
                field: "is_member",
                operator: "is",
                value: true,
            },
            {
                order: 100000001,
                condition: "and",
                field: "adresses.id",
                operator: "greater",
                value: 0,
            },
            {
                order: 100000001,
                condition: "and",
                field: "adresses.latitude_coordonnate",
                operator: "greater",
                value: -1000000000,
            },
            {
                order: 100000001,
                condition: "and",
                field: "adresses.longitude_coordonnate",
                operator: "greater",
                value: -1000000000,
            },
            {
                order: 100000001,
                condition: "and",
                field: "status",
                operator: "is",
                value: ["actived", "imported"],
            },
            // {
            //   order: 100000001,
            //   condition: "and",
            //   field: "code_scian_primaire.is_visible",
            //   operator: "is",
            //   value: true,
            // },
        ]);
        setRowsProjects([
            {
                order: 100000001,
                condition: "where",
                field: "adresse.id",
                operator: "greater",
                value: 0,
            },
            {
                order: 100000001,
                condition: "and",
                field: "adresse.latitude_coordonnate",
                operator: "greater",
                value: -1000000000,
            },
            {
                order: 100000001,
                condition: "and",
                field: "adresse.longitude_coordonnate",
                operator: "greater",
                value: -1000000000,
            },
            {
                order: 100000001,
                condition: "and",
                field: "visible_publiquement",
                operator: "is",
                value: true,
            },
            {
                order: 100000001,
                condition: "and",
                field: "entreprise.id",
                operator: "greater",
                value: 0,
            },
            {
                order: 100000001,
                condition: "and",
                field: "entreprise.status",
                operator: "is",
                value: ["actived", "imported"],
            },
            {
                order: 100000001,
                condition: "and",
                field: "entreprise.code_scian_primaire.is_visible",
                operator: "is",
                value: true,
            },
            {
                order: 100000001,
                condition: "and",
                field: "visible_in_map",
                operator: "is",
                value: true,
            },
        ]);
        setPage(0);
        setSearchInput('');
        setSearchedTerm('');
    };
    // View function
    var handleView = function () {
        if (view === "carte") {
            setPage(0);
            setView("list");
        }
        else {
            setView("carte");
        }
    };
    // Handle Search Input
    var handleSearchInput = function (e) {
        setSearchInput(e.target.value);
    };
    // Handle Search Term
    var handleSearchTerm = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    // Handle Search KeyDown
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
        }
    };
    // Handle Regions change
    var handleRegionsChange = function (item) {
        setPage(0);
        var latestSelected = selectedRegions
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedRegions.includes(x); }));
        var newRegion = function (field) { return ({
            order: 99999999,
            condition: "or",
            field: field,
            operator: "is",
            value: latestSelected[0].value,
        }); };
        var updateRows = function (rows, field) {
            var newRegionItem = newRegion(field);
            if (rows.some(function (obj) { return Object.values(obj).includes(field); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === field; });
                newRegionItem.order = rows[indx].order;
            }
            else {
                newRegionItem.order = getHighestOrder(rows) + 1;
            }
            return __spreadArray(__spreadArray([], __read(rows), false), [newRegionItem], false);
        };
        if (item.length > selectedRegions.length) {
            setSelectedRegions(item);
            setRowsCompanies(function (prevRows) { return updateRows(prevRows, "adresses.region_adminsitrative.id"); });
            setRowsProjects(function (prevRows) { return updateRows(prevRows, "adresse.region_adminsitrative.id"); });
            setRowsCompanies(function (prevRows) { return updateRows(prevRows, "autres_address.region_adminsitrative.id"); });
        }
        else {
            var removedOption_1 = selectedRegions
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedRegions.includes(x); }));
            setSelectedRegions(item);
            setRowsCompanies(function (prevRows) {
                return prevRows.filter(function (row) { return row.value !== removedOption_1[0].value; });
            });
            setRowsProjects(function (prevRows) {
                return prevRows.filter(function (row) { return row.value !== removedOption_1[0].value; });
            });
        }
    };
    // Handle Categories change
    var handleCategoriesChange = function (item) {
        setPage(0);
        //get latest selected
        var latestSelected = selectedCategories
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
        var newSector = null;
        var newSectorProject = null;
        if (latestSelected[0].value != "avis" &&
            latestSelected[0].value != "nonavis") {
            newSector = {
                order: 99999999,
                condition: "and",
                field: "type_company",
                operator: "is",
                value: latestSelected[0].value,
            };
        }
        else {
            newSectorProject = {
                order: 99999999,
                condition: "and",
                field: "is_avis",
                operator: "is",
                value: latestSelected[0].value == "avis" ? true : false,
            };
        }
        if (item.length > selectedCategories.length) {
            setSelectedCategories(item);
            if (newSector) {
                setRowsCompanies(function (prevStat) {
                    return __spreadArray(__spreadArray([], __read(prevStat), false), [newSector], false);
                });
            }
            else {
                setRowsProjects(function (prevStat) {
                    return __spreadArray(__spreadArray([], __read(prevStat), false), [newSectorProject], false);
                });
            }
            // ===---
            if (newSector) {
                if (rowsCompanies.some(function (obj) {
                    return Object.values(obj).includes("type_company");
                })) {
                    var indx = rowsCompanies.findIndex(function (obj) { return obj["field"] === "type_company"; });
                    newSector.order = rowsCompanies[indx].order;
                }
                else {
                    var bigOrder = getHighestOrder(rowsCompanies);
                    newSector.order = bigOrder + 1;
                }
            }
            // ===---
            if (newSectorProject) {
                if (rowsProjects.some(function (obj) { return Object.values(obj).includes("is_avis"); })) {
                    var indx = rowsProjects.findIndex(function (obj) { return obj["field"] === "is_avis"; });
                    newSectorProject.order = rowsProjects[indx].order;
                }
                else {
                    var bigOrder = getHighestOrder(rowsProjects);
                    newSectorProject.order = bigOrder + 1;
                }
            }
            // ===---
        }
        else {
            //get the removed option
            var removedOption_2 = selectedCategories
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedCategories.includes(x); }));
            setSelectedCategories(item);
            if (newSector) {
                // setRowsCompanies((prevRowsCompanies) =>
                //   prevRowsCompanies.filter((row) => row.value !== removedOption.value)
                // );
                setRowsCompanies(function () { return rowsCompanies.filter(function (item) { return item.value != removedOption_2[0].value; }); });
            }
            else {
                setRowsProjects(function (prevRowsProjects) {
                    return prevRowsProjects.filter(function (row) {
                        if (row.field === 'is_avis') {
                            return row.value !== newSectorProject.value;
                        }
                        return row.value !== removedOption_2.value;
                    });
                });
            }
        }
    };
    // Handle Sectors change
    var handleSectorsChange = function (item) {
        setPage(0);
        var latestSelected = selectedSectors
            .filter(function (x) { return !item.includes(x); })
            .concat(item.filter(function (x) { return !selectedSectors.includes(x); }));
        var newSector = function (field) { return ({
            order: 99999999,
            condition: "and",
            field: field,
            operator: "istartswith",
            value: latestSelected[0].value,
        }); };
        var updateRows = function (rows, field) {
            var newItem = newSector(field);
            if (rows.some(function (obj) { return Object.values(obj).includes(field); })) {
                var indx = rows.findIndex(function (obj) { return obj["field"] === field; });
                newItem.order = rows[indx].order;
            }
            else {
                newItem.order = getHighestOrder(rows) + 1;
            }
            return __spreadArray(__spreadArray([], __read(rows), false), [newItem], false);
        };
        if (item.length > selectedSectors.length) {
            setSelectedSectors(item);
            setRowsCompanies(function (prevRows) { return updateRows(prevRows, "code_scian_primaire.code"); });
            setRowsCompanies(function (prevRows) { return updateRows(prevRows, "code_scian_secondaire.code"); });
            setRowsProjects(function (prevRows) {
                return updateRows(prevRows, "entreprise.code_scian_primaire.code");
            });
            setRowsProjects(function (prevRows) {
                return updateRows(prevRows, "entreprise.code_scian_secondaire.code");
            });
        }
        else {
            var removedOption_3 = selectedSectors
                .filter(function (x) { return !item.includes(x); })
                .concat(item.filter(function (x) { return !selectedSectors.includes(x); }));
            setSelectedSectors(item);
            setRowsCompanies(function (prevRows) {
                return prevRows.filter(function (row) { return row.value !== removedOption_3[0].value; });
            });
            setRowsProjects(function (prevRows) {
                return prevRows.filter(function (row) { return row.value !== removedOption_3[0].value; });
            });
        }
    };
    // Handle page change
    var handlePageClick = function (e) {
        setPage(e.selected);
        document.getElementById("map-wrapper").scrollIntoView();
    };
    return (_jsx(MapContext.Provider, __assign({ value: {
            initialDatas: initialDatas,
            setInitialDatas: setInitialDatas,
            updatedKey: updatedKey,
            companies: companies,
            setCompanies: setCompanies,
            projects: projects,
            setProjects: setProjects,
            companiesList: companiesList,
            projectsList: projectsList,
            regions: regions,
            setRegions: setRegions,
            regionsNorth: regionsNorth,
            setRegionsNorth: setRegionsNorth,
            selectedRegions: selectedRegions,
            setSelectedRegions: setSelectedRegions,
            // selectedRegionsNorth,
            // setSelectedRegionsNorth,
            categories: categories,
            setCategories: setCategories,
            selectedCategories: selectedCategories,
            setSelectedCategories: setSelectedCategories,
            sectors: sectors,
            setSectors: setSectors,
            datasIsLoading: datasIsLoading,
            datasListIsLoading: datasListIsLoading,
            selectedSectors: selectedSectors,
            setSelectedSectors: setSelectedSectors,
            // handleNorthRegionChange,
            // handleOtherRegionChange,
            handleRegionsChange: handleRegionsChange,
            handleCategoriesChange: handleCategoriesChange,
            handleSectorsChange: handleSectorsChange,
            resetFilters: resetFilters,
            searchInput: searchInput,
            handleSearchInput: handleSearchInput,
            handleSearchTerm: handleSearchTerm,
            handleKeyDown: handleKeyDown,
            view: view,
            handleView: handleView,
            handlePageClick: handlePageClick,
            page: page,
            pageCount: pageCount,
            rowsProjects: rowsProjects,
            setRowsProjects: setRowsProjects,
        } }, { children: children })));
};
export default MapContext;
