var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children } from 'react';
import { useTranslation } from "react-i18next";
import { CardStyle } from './Card-styles';
import { motion } from "framer-motion";
import { MultiSelectionWrapper } from "../../../shared-components/SideFilter/MultiSelectionWrapper/MultiSelectionWrapper";
export var Card = function (_a) {
    var _b;
    var children = _a.children, topPosition = _a.topPosition, zIndex = _a.zIndex;
    var t = useTranslation(['news']).t;
    var cleanFiltersBtn = Children.toArray(children)[0];
    var multiSelections = Children.toArray(children)[1];
    var currentAuthStatus = ((_b = document.getElementById("auth-user")) === null || _b === void 0 ? void 0 : _b.value) || false;
    var isAthencticated = currentAuthStatus == 'True' ? true : false;
    return (_jsx(React.Fragment, { children: isAthencticated && (_jsx(CardStyle, __assign({ cardPosition: topPosition, zIndex: zIndex }, { children: _jsx(MultiSelectionWrapper, __assign({ title: t("Filtres"), marginTop: 0, marginBottom: 0 }, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: _jsxs("div", __assign({ className: "d-flex justify-content-start flex-column" }, { children: [_jsx("span", __assign({ className: "w-100 mt-3" }, { children: cleanFiltersBtn })), _jsx("span", __assign({ className: "w-100 mt-3" }, { children: multiSelections }))] })) })) })) }))) }));
};
