var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import MapContext from "../../../application/Context/MapContext";
export var Legend = function () {
    var t = useTranslation(["common"]).t;
    var _a = React.useContext(MapContext), companies = _a.companies, projects = _a.projects;
    return (_jsx(React.Fragment, { children: _jsx("div", __assign({ className: "tags-qty-underline" }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsx("div", __assign({ className: "content" }, { children: _jsxs("ul", { children: [_jsxs("li", { children: [_jsx("div", __assign({ className: "bubble blue-moyen rounded-0" }, { children: _jsx("div", __assign({ className: "qty" }, { children: companies.filter(function (c) {
                                                return c.adresses.latitude_coordonnate &&
                                                    c.type_company == "contractor";
                                            }).length })) })), t("Donneurs d'ordres")] }), _jsxs("li", { children: [_jsx("div", __assign({ className: "bubble blue rounded-0" }, { children: _jsx("div", __assign({ className: "qty" }, { children: companies.filter(function (c) {
                                                return c.adresses.latitude_coordonnate &&
                                                    c.type_company == "supplier";
                                            }).length })) })), t("Fournisseurs")] }), _jsxs("li", { children: [_jsx("div", __assign({ className: "bubble blue-piv rounded-0" }, { children: _jsx("div", __assign({ className: "qty" }, { children: companies.filter(function (c) {
                                                return c.adresses.latitude_coordonnate &&
                                                    c.type_company == "intervenant";
                                            }).length })) })), t("Intervenants")] }), _jsxs("li", { children: [_jsx("div", __assign({ className: "bubble vert rounded-0" }, { children: _jsx("div", __assign({ className: "qty" }, { children: projects.filter(function (p) { return p.adresse.latitude_coordonnate && p.is_avis; }).length })) })), t("Avis publics")] }), _jsxs("li", { children: [_jsx("div", __assign({ className: "bubble vert-pale rounded-0" }, { children: _jsx("div", __assign({ className: "qty" }, { children: projects.filter(function (p) { return p.adresse.latitude_coordonnate && !p.is_avis; }).length })) })), t("Avis des donneurs d'ordres")] })] }) })) })) })) }));
};
