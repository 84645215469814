var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ViewTogglerContainer, View } from "./ViewStyles";
import MapContext from "../../../../application/Context/MapContext";
export var ViewToggler = function () {
    var t = useTranslation(['news']).t;
    var _a = useContext(MapContext), view = _a.view, handleView = _a.handleView;
    return (_jsx(React.Fragment, { children: _jsxs(ViewTogglerContainer, __assign({ position: view == "carte" ? "absolute" : "static", marginTop: view == "carte" ? 0 : 24, style: { justifyContent: "flex-end" } }, { children: [_jsxs(View, __assign({ onClick: view == "carte" ? null : handleView, isActive: view == "carte" ? true : false }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/").concat(view == "carte" ? "carte-blue.svg" : "carte-grey.svg", " "), alt: t('Vue de la carte') }), _jsx("span", { children: t("Vue de la carte") })] })), _jsxs(View, __assign({ onClick: view == "carte" ? handleView : null, isActive: view == "list" ? true : false }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/").concat(view == "list" ? "list-blue.svg" : "list-grey.svg"), alt: t('Vue de la liste') }), _jsx("span", { children: t("Vue de la liste") })] }))] })) }));
};
