var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import Theme from "../components/react-components/Styles/Theme";
import { MapProvider } from "./Context/MapContext";
import { View } from "../components/react-components/map-components/Views/View";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
export var Map = function () {
    React.useEffect(function () {
        var _a;
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(MapProvider, { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx(React.Fragment, { children: _jsx(View, {}) }) })) }) })) }));
};
var domNode = document.querySelector("#map");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(Map, {}));
}
