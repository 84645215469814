var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var ViewTogglerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nposition:", ";\nz-index:1000;\ndisplay:flex;\ngap:32px;\nmargin-top:", "px;\nmargin-left:auto;\nright: 10%;\n\n  @media screen and (max-width: 768px) {\n    top: 110px;\n    span {\n      display: none;\n    }\n  }\n  @media screen and (min-width: 768px) {\n    top: 60px;\n\n  }\n\n"], ["\nposition:", ";\nz-index:1000;\ndisplay:flex;\ngap:32px;\nmargin-top:", "px;\nmargin-left:auto;\nright: 10%;\n\n  @media screen and (max-width: 768px) {\n    top: 110px;\n    span {\n      display: none;\n    }\n  }\n  @media screen and (min-width: 768px) {\n    top: 60px;\n\n  }\n\n"])), function (_a) {
    var position = _a.position;
    return position;
}, function (_a) {
    var marginTop = _a.marginTop;
    return marginTop;
});
export var View = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n background-color: ", ";\n display:flex;\n gap:0.6rem;\n padding:12px 20px;\n color:  ", ";\n font-weight: ", ";\n cursor: pointer;\n align-items:center;\n img {\n    width: 24px;\n    height: 24px;\n }\n"], ["\n background-color: ", ";\n display:flex;\n gap:0.6rem;\n padding:12px 20px;\n color:  ", ";\n font-weight: ", ";\n cursor: pointer;\n align-items:center;\n img {\n    width: 24px;\n    height: 24px;\n }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.grisPale;
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive ? "".concat(theme.colors.bluePiv) : "".concat(theme.colors.grisMoyen);
}, function (_a) {
    var isActive = _a.isActive;
    return isActive ? "bold" : "regular";
});
var templateObject_1, templateObject_2;
