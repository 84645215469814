var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { SearchBarContainer } from "./search-input-styles";
export var SearchInput = function (_a) {
    var placeholder = _a.placeholder, handleSearch = _a.handleSearch, otherProps = __rest(_a, ["placeholder", "handleSearch"]);
    return (_jsx(React.Fragment, { children: _jsxs(SearchBarContainer, { children: [_jsx("input", __assign({ placeholder: placeholder }, otherProps)), _jsx("button", __assign({ type: "submit", onClick: handleSearch }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/recherche.svg"), alt: "search" }) }))] }) }));
};
